"use client";

import { Box, Container } from "@mui/material";
import { PublicHeaderStatic } from "@/app/(espace-pro)/_components/PublicHeader";
import { Footer } from "@/app/_components/Footer";
import NotFound from "@/app/_components/NotFound";
export default function NotFoundPage() {
  return <Box sx={{
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "max-content 1fr min-content"
  }} data-sentry-element="Box" data-sentry-component="NotFoundPage" data-sentry-source-file="not-found.tsx">
      <PublicHeaderStatic data-sentry-element="PublicHeaderStatic" data-sentry-source-file="not-found.tsx" />
      <Container maxWidth="xl" data-sentry-element="Container" data-sentry-source-file="not-found.tsx">
        <NotFound data-sentry-element="NotFound" data-sentry-source-file="not-found.tsx" />
      </Container>
      <Footer data-sentry-element="Footer" data-sentry-source-file="not-found.tsx" />
    </Box>;
}