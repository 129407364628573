import { fr } from "@codegouvfr/react-dsfr";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";
import { DsfrLink } from "@/components/dsfr/DsfrLink";
const NotFound = () => <Box sx={{
  display: "grid",
  gridTemplateColumns: {
    xs: "1fr",
    md: "1fr 1fr"
  },
  gap: fr.spacing("4w"),
  height: "100%",
  alignItems: "center",
  justifyContent: "center"
}} data-sentry-element="Box" data-sentry-component="NotFound" data-sentry-source-file="NotFound.tsx">
    <Box data-sentry-element="Box" data-sentry-source-file="NotFound.tsx">
      <Typography variant="h1" data-sentry-element="Typography" data-sentry-source-file="NotFound.tsx">404</Typography>
      <Typography className={fr.cx("fr-text--lg", "fr-text--bold")} data-sentry-element="Typography" data-sentry-source-file="NotFound.tsx">Vous êtes perdu·e ?</Typography>
      <Typography className={fr.cx("fr-text--sm")} data-sentry-element="Typography" data-sentry-source-file="NotFound.tsx">
        Il semble que la page que vous essayez de rejoindre n&apos;existe pas. En cas de problème pour retrouver la page, essayez de repartir de la page d&apos;accueil en cliquant
        sur le lien ci-dessous.
      </Typography>
      <Box sx={{
      mt: fr.spacing("2w")
    }} data-sentry-element="Box" data-sentry-source-file="NotFound.tsx">
        <DsfrLink href="/" size="lg" arrow="right" data-sentry-element="DsfrLink" data-sentry-source-file="NotFound.tsx">
          Page d&apos;accueil
        </DsfrLink>
      </Box>
    </Box>
    <Box sx={{
    display: "flex",
    justifyContent: "center"
  }} data-sentry-element="Box" data-sentry-source-file="NotFound.tsx">
      <Image src="/images/lostCat.svg" alt="" width={771} height={290} style={{
      width: "75%"
    }} data-sentry-element="Image" data-sentry-source-file="NotFound.tsx" />
    </Box>
  </Box>;
export default NotFound;